import { Link } from 'gatsby';
import React from 'react';

export const LinkComponent = ({ link, title }) => {
  return (
    <Link className='card-container link-card' to={link}>
      <span className='card-wrap-title'>
        <span className='card-title'>{title}</span>
      </span>
    </Link>
  );
};
